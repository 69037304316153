import axios from "axios";

export const backendClient = (params) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
    ...params,
  });
}

export const simpleClient = (params) => {
  return axios.create({
    xsrfHeaderName: "X-CSRFTOKEN",
    xsrfCookieName: "csrftoken",
    ...params,
  });
}
