import * as React from "react";
import PropTypes from "prop-types";
import { Autocomplete, InputAdornment, Stack, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormControl } from "../atoms/FormControl";
import { FormLabel } from "@mui/material";
import { PropertyColumnResetButton } from "../molecules/PropertyColumnResetButton";


export const SelectForm = React.forwardRef((props, ref) => { // eslint-disable-line no-unused-vars

  const { disableClearable, loading, onFocus, onChange, sx, isLabelInside, label, labelSx, defaultValue, name, control, rules, choices, disabled, fullWidth, labelPosition, setValue, freeSolo, color, selectOnFocus, clearOnBlur, handleHomeEndKeys, resetAction, ...selectProps } = props

  const defaultLabelSx = labelPosition === "top" ? {} : { whiteSpace: "nowrap" }

  const valueToLabel = React.useCallback((value) => {
    const choice = choices.find((choice) => choice.value === value)
    return choice?.label ?? (value ?? "")
  }, [choices])

  const handleResetAction = React.useCallback((e) => {
    e.stopPropagation()
    resetAction(name)
  }, [name, resetAction])

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <FormControl error={fieldState.error ? true : false} fullWidth={fullWidth}>
            <Stack
              spacing={labelPosition === "top" ? undefined : 1}
              direction={labelPosition === "top" ? "column" : (labelPosition === "right" ? "row-reverse" : "row")}
              alignItems={labelPosition === "top" ? undefined : "center"}
              justifyContent={labelPosition === "top" ? undefined : "center"}
            >
              {(isLabelInside === false && label) && <FormLabel sx={{ ...defaultLabelSx, ...labelSx }}>{label}</FormLabel>}
              <Autocomplete
                {...field}
                {...selectProps}
                openText="開く"
                noOptionsText="選択肢が見つかりません"
                options={choices}
                getOptionDisabled={(option) => option.disabled}
                value={valueToLabel(field.value)}
                isOptionEqualToValue={(option, value) => {
                  return value === "" || option.label === value
                }}
                fullWidth
                disableClearable={disableClearable}
                loading={loading}
                loadingText={loading ? "読み込み中..." : ""}
                openOnFocus
                freeSolo={true}
                selectOnFocus={selectOnFocus === undefined ? !freeSolo : selectOnFocus}
                clearOnBlur={clearOnBlur === undefined ? !freeSolo : selectOnFocus}
                handleHomeEndKeys={handleHomeEndKeys === undefined ? !freeSolo : selectOnFocus}
                renderInput={(params) => {
                  const { InputProps, ...otherParams } = params
                  return (
                    <TextField
                      {...otherParams}
                      color={color}
                      label={isLabelInside ? label : undefined}
                      error={fieldState.error ? true : false}
                      helperText={fieldState.error?.message}
                      InputProps={{
                        ...InputProps,
                        sx: sx,
                        startAdornment: (
                          <>
                            {InputProps?.startAdornment}
                            {resetAction &&
                              <InputAdornment position="start">
                                <PropertyColumnResetButton onClick={handleResetAction} />
                              </InputAdornment>
                            }
                          </>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(event) => {
                        if (freeSolo) {
                          setValue(name, event.target.value);
                        }
                        if (onChange) {
                          onChange(event)
                        }
                      }}
                    />
                  )
                }
                }
                onChange={(event, value) => {
                  if (value !== null && typeof value === "object" && "value" in value) {
                    setValue(name, value.value);
                  } else {
                    setValue(name, "");
                  }
                }}
                onFocus={() => {
                  if (typeof onFocus === "function") {
                    onFocus(name)
                  }
                }}
                disabled={disabled}
              />
            </Stack>
          </FormControl>
        )
      }}
    />
  );
});

SelectForm.defaultProps = {
  choices: [],
  isLabelInside: false,
  labelPosition: "top",
  fullWidth: true,
  size: "small",
  labelSx: {},
  disableClearable: false,
  freeSolo: false,
  loading: false,
}

SelectForm.propTypes = {
  label: PropTypes.any,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rules: PropTypes.object,
  choices: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isLabelInside: PropTypes.bool,
  labelPosition: PropTypes.oneOf(["top", "left", "right"]),
  labelSx: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  sx: PropTypes.object,
  freeSolo: PropTypes.bool,
  disableClearable: PropTypes.bool,
  color: PropTypes.string,
  selectOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  handleHomeEndKeys: PropTypes.bool,
  resetAction: PropTypes.func,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
};

SelectForm.displayName = "SelectForm"